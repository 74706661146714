import React from "react";
import MainSlider from "./Includes/MainSlider";
// import About from "./Components/About";
import Amenities from "./Amenities";
import Overview from "./Overview";
import Gallery from "./Gallery";
// import SideForm from '../Components/SideForm';
import Location from "./Location";
import Pricing from "./Pricing";
import MasterPlan from "./MaterPlan/MasterPlan";
// import VirtualTour from './Components/VirtualTour';
// import Contact from './Contact';
import ProjectHighlights from "./ProjectHighlights";
import GetInTouch from "./GetInTouch";
import { Col, Container, Row } from "react-bootstrap";

const Home = () => {
  return (
    <>
      <MainSlider />
      <Overview />

      <div id="new-section" className="">
        <Container>
          <Row>
            <Col md={12}>
              <div className="py-2">
                <h2 className="text-primary fs-3">
                  More than a dream home - Mahindra Eden Launch Kanakapura
                </h2>
                <div className="rounded">
                  <p>
                    We have earned a reputation for building top-quality
                    apartments containing all the amenities you and your family
                    need to relish. We plan to break our previous records by
                    creating a new complex containing all the conveniences you
                    might need to live a carefree life. Instead of building a
                    concrete jungle, we have included lots of greenery into our
                    project. The extensive lawn in the complex allows you and
                    your family members to frolic and breathe in the fresh air.
                    Proper planning ensures adequate ventilation and sunshine in
                    each flat. Relax your tired bones at the end of a hectic day
                    by diving into the swimming pool. The other exclusive
                    niceties of this property include
                  </p>

                  <ul>
                    <li>Fully equipped clubhouse</li>
                    <li>Health club</li>
                    <li>Gym</li>
                    <li>Party halls</li>
                    <li>Badminton court</li>
                    <li>Table tennis</li>
                    <li>Billiards table</li>
                    <li>Reading room</li>
                    <li>Board games</li>
                    <li>Provision for a crèche</li>
                  </ul>
                </div>
              </div>
              <div className="py-2">
                <h3 className="text-primary fs-3">
                  A Fast-Developing Community - Mahindra Eden in Bannerghatta
                  Road
                </h3>
                <div className="rounded">
                  <p>
                    Most of the upcoming residential projects in Bengaluru
                    comprise congested concrete jungles that hardly allow
                    residents to chill after returning home from work. However,
                    this is not the case with residential apartments built by
                    us. We have thoughtfully included ample open spaces in each
                    of our projects, including the upcoming <span className="fw-bold">Mahindra Eden in
                    Bannerghatta Road</span>. Apart from being an excellent and fast
                    developing locality, Bannerghatta Road lies close to malls,
                    temples, colleges, schools, and hospitals. Currently, you
                    can use bus services to travel to your destination. Once
                    completed, the Pink Line of Namma Metro will connect this
                    locality with the rest of the city. On holidays, take your
                    family members to Bannerghatta Biological Park. Religious
                    minded people can relax their minds by visiting religious
                    places like
                  </p>

                  <ul>
                    <li>Champakadhama Swamy Temple</li>
                    <li>Sri Meenakshi Sundareshwara Temple, and</li>
                    <li>Sri Champakadhama Swamy Devalaya</li>
                  </ul>
                </div>
              </div>
              <div className="py-2">
                <h3 className="text-primary fs-3">
                  Different sized and affordable apartments – Mahindra Eden
                  Launch Kankarpura
                </h3>
                <div className="rounded">
                  <p>
                    You can choose from a wide range of apartments such as
                    1-BHK, 2-BHK, 3-BHK, and 3-BHK + study. Instead of waiting
                    for prices to escalate during <span className="fw-bold">Mahindra Eden launch</span> Kankarpura, book your dream home during our prelaunch period
                    to get massive discounts. Considering the prime location of
                    the property, the prices of our apartments are
                    pocket-friendly.
                  </p>
                  <p className="fw-bold">Mahindra Eden Price</p>
                  <ul>
                    <li>
                      1-BHK flat covering 600 square feet built up area costs
                      Rs.45 lacs onwards
                    </li>
                    <li>
                      2-BHK apartment covering 1,200 square feet built up area
                      costs Rs.90 lacs onwards
                    </li>
                    <li>
                      3-BHK flat covering 1,600 square feet built up area costs
                      Rs.1.3 crore onwards
                    </li>
                    <li>
                      3-BHK flat covering 1,800 square feet built up area costs
                      Rs.1.45 crore onwards
                    </li>
                    <li>
                      3-BHK flat + study covering 2,000 square feet built up
                      area costs Rs.1.65 crore onwards
                    </li>
                  </ul>
                </div>
              </div>
              <div className="py-2">
                <h4 className="text-primary fs-3">Act today</h4>
                <div className="rounded">
                  <p>
                    Do not delay, as you will not get flats at such rates in a
                    posh locality. Also, the prices will escalate after the
                    construction and furnishing of the apartments. You can find
                    the details of the flats and view the master plan and floor
                    plan on our website. The gallery on our online portal also
                    contains an image gallery containing photos of the exterior
                    and interior of the flats.
                  </p>
                </div>
              </div>
              <div className="py-2">
                <h4 className="text-primary fs-3">Built to last a lifetime - Mahindra Eden Review</h4>
                <div className="rounded">
                  <p>
                  We have taken extreme care to use the best materials for constructing the apartments. All the flats contain fittings and fixtures in the bathroom and kitchen. A backup generator provides power during a blackout. It ensures that you receive power round the clock. Hopefully, you will find all answers to your questions in this <span className="fw-bold">Mahindra Eden review</span>. Interested people have already booked some of the prime flats in this apartment. Contact the developer today and book your preferred flat in <span className="fw-bold">Mahindra Eden in Bangalore</span> before it is too late.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <ProjectHighlights />
      <Pricing />
      <MasterPlan />
      <Gallery />
      <Amenities />
      {/* <VirtualTour /> */}
      {/* <SideForm /> */}
      <Location />
      <GetInTouch />
    </>
  );
};

export default Home;
